@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:wght@400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #f3f0e9;
  --secondary: #c8c8c8;
}
@layer components {
  .flex-c {
    @apply flex justify-center items-center text-center;
  }
  .abs-c {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute;
  }
  .btn {
    @apply py-2 px-6  rounded-full font-semibold transition-all duration-300 ease-in-out leading-normal;
  }
  .btn-main {
    @apply bg-primary hover:bg-secondary text-white;
  }
  .min-h-dscreen {
    @apply min-h-[100dvh];
  }
  .max-w-modal {
    @apply w-screen md:max-w-2xl;
  }
  .max-screen {
    @apply px-4 xl:px-0 max-w-screen-lg xl:max-w-[1150px] 2xl:max-w-[1400px] 3xl:max-w-screen-2xl  mx-auto;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  @apply text-primary;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
.main-title {
  @apply text-secondary text-3xl font-bold uppercase relative text-center pb-5 mb-10;
}

.main-title::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #f3f0e9;
  bottom: 0;
  left: calc(50% - 20px);
}

.main-title:before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #f3f0e9;
  border: 2px solid var(--primary);
}
.loader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--primary);
  border-top-color: #f3f0e9;
  border-bottom-color: #f3f0e9;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-loader 1s linear infinite;
}

@keyframes animate-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.scrolling-image {
  animation: scroll 30s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scrolling-image-container:hover .scrolling-image {
  animation-play-state: paused;
}

